import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPageRouting from './Components/English/Landing/LandingPageRouting'
import ForexRouting from './Components/English/Markets/Forex/ForexRouting'
import CommoditiesRouting from './Components/English/Markets/Commodities/CommoditiesRouting'
import IndicesRouting from './Components/English/Markets/Indices/IndicesRouting'
import MetalsRouting from './Components/English/Markets/Metals/MetalsRouting'
import StocksRouting from './Components/English/Markets/Stocks/StocksRouting'
import PlatformRouting from './Components/English/Platforms/PlatformRouting'
import AboutUsRouting from './Components/English/Abouts/About-Us/AboutUsRouting'
import BoardMembersRouting from './Components/English/Abouts/Borad-of-members/BoardMembersRouting'
import RegulationRouting from './Components/English/Abouts/Regulation/RegulationRouting'
import CareersRouting from './Components/English/Abouts/Careers/CareersRouting'
import ContactUsRouting from './Components/English/Contact/ContactUsRouting'

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPageRouting/>}/>
        <Route path='/en' element={<LandingPageRouting/>}/>
        <Route path='/en/home' element={<LandingPageRouting/>}/>
        <Route path='/en/forex' element={<ForexRouting/>}/>
        <Route path='/en/commodities' element={<CommoditiesRouting/>}/>
        <Route path='/en/indices' element={<IndicesRouting/>}/>
        <Route path='/en/metals' element={<MetalsRouting/>}/>
        <Route path='/en/stocks' element={<StocksRouting/>}/>
        <Route path='/en/metatrader5' element={<PlatformRouting/>}/>
        <Route path='/en/about-us' element={<AboutUsRouting/>}/>
        <Route path='/en/board-of-members' element={<BoardMembersRouting/>}/>
        <Route path='/en/regulation' element={<RegulationRouting/>}/>
        <Route path='/en/career' element={<CareersRouting/>}/>
        <Route path='/en/contact-us' element={<ContactUsRouting/>}/>




        {/* Arabic */}
        <Route path='/ar/home' element={<LandingPageRouting/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App


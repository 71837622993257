import React , {lazy,Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../Footer/Footer'));
const Mt5Hero = lazy(()=>import('./Mt5Hero/Mt5Hero'));
const Metatrader5 = lazy(()=>import('./Metatrader5/Metatrader5'));
const Mt5WhyTrade = lazy(()=>import('./Mt5WhyTrade/Mt5WhyTrade'));
const Mt5AccountTypes = lazy(()=>import('./Mt5AccountTypes/Mt5AccountTypes'));
const Mt5Faq = lazy(()=>import('./Mt5Faq/Mt5Faq'));

const PlatformRouting = () => {
  return (
    <div>
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar  />
        </Suspense>
      </div>
      <Suspense><Mt5Hero/></Suspense>
      <Suspense><Metatrader5/></Suspense>
      <Suspense><Mt5WhyTrade/></Suspense>
      {/* <Suspense><Mt5AccountTypes/></Suspense> */}
      <Suspense><Mt5Faq/></Suspense>
      <Suspense><Footer/></Suspense>
    </div>
  )
}

export default PlatformRouting

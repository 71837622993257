import React , {lazy , Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const CareersHero = lazy(()=>import('./CareersHero/CareersHero'));
const CareerOpenings = lazy(()=>import('./CareersOpenings/CareerOpenings'));
const CareersBenifits = lazy(()=>import('./CareersBenifits/CareersBenifits'));

const CareersRouting = () => {
  return (
    <div>
      <Suspense fallback={<div></div>}>
      <div className="navbarPositions">
          <NavigationBar />
      </div>
      <CareersHero/>
      <CareerOpenings/>
      <CareersBenifits/>
      <Footer/>
      </Suspense>
    </div>
  )
}

export default CareersRouting

import React , {lazy,Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const BoardMembersHero = lazy(()=>import('./BoardMembersHero/BoardMembersHero'));
const BoardMembers = lazy(()=>import('./BoardMembers/BoardMembers'));

const BoardMembersRouting = () => {
  return (
    <div>
      <Suspense fallback={<div></div>}>
      <div className="navbarPositions">
          <NavigationBar />
      </div>
      <BoardMembersHero/>
      <BoardMembers/>
      <Footer/>
      </Suspense>
    </div>
  )
}

export default BoardMembersRouting

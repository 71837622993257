// LandingPageRouting.js
import React, {lazy, Suspense } from "react";
const LandingHero = lazy(() =>
  import("./LandingHero/LandingHero")
);
const LandingMarketInfo = lazy(() =>
  import("./LandingMarketInfo/LandingMarketInfo")
);
const LandingPlatformMt5 = lazy(() => import("./Platform/LandingPlatformMt5"));
const LandingWhyInvest = lazy(() =>
  import("./LandingWhyInvest/LandingWhyInvest")
);
const LandingKamaTeam = lazy(() =>
  import("./LandingKamaTeam/LandingKamaTeam")
);
const LandingBlogs = lazy(() => import("./LandingBlogs/LandingBlogs"));
const Footer = lazy(() => import("../Footer/Footer"));
const NavigationBar = lazy(() => import("../../Navbar/NavigationBar"));

const LandingPageRouting = () => {
  

  return (
    <div className="landing-page-container" >
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar />
        </Suspense>
      </div>
      <section  className="section">
        <Suspense fallback={<div></div>}>
        <LandingHero  />
        </Suspense>
      </section>
      <section  className="section">
        <Suspense fallback={<div></div>}>
          <LandingMarketInfo />
        </Suspense>
      </section>
      <section  className="section">
        <Suspense fallback={<div></div>}>
          <LandingPlatformMt5 />
        </Suspense>
      </section>
      <section  className="section">
        <Suspense fallback={<div></div>}>
          <LandingWhyInvest />
          <LandingKamaTeam />
          {/* <LandingBlogs /> */}
        </Suspense>
      </section>
      <Suspense fallback={<div></div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default LandingPageRouting;

import React , {lazy , Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const RegulationHero = lazy(()=>import('./RegulationHero/RegulationHero'));
const OurRegulation = lazy(()=>import('./OurRegulation/OurRegulation'));

const RegulationRouting = () => {
  return (
    <div>
      <Suspense fallback={<div></div>}>
      <div className="navbarPositions">
          <NavigationBar />
      </div>
      <RegulationHero/>
      <OurRegulation/>
      <Footer/>
      </Suspense>
    </div>
  )
}

export default RegulationRouting

import React , {lazy,Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const MetalsHero = lazy(()=>import('./MetalsHero/MetalsHero'));
const MetalsGlobalMarkets = lazy(()=>import('./MetalsGlobalMarkets/MetalsGlobalMarkets'));
const BenefitsofMetalTrading = lazy(()=>import('./BenefitsofMetalTrading/BenefitsofMetalTrading'));
const HowToTradeMetals = lazy(()=>import('./HowToTradeMetals/HowToTradeMetals'));
const MetalsRouting = () => {
  return (
    <div>
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar  />
        </Suspense>
      </div>
      <Suspense><MetalsHero/></Suspense>
      <Suspense><MetalsGlobalMarkets/></Suspense>
      <Suspense><BenefitsofMetalTrading/></Suspense>
      <Suspense><HowToTradeMetals/></Suspense>
      <Suspense><Footer/></Suspense>
    </div>
  )
}

export default MetalsRouting

import React , {lazy,Suspense} from 'react'
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const IndicesHero = lazy(()=>import('./IndicesHero/IndicesHero'));
const IndicesGlobalMarkets = lazy(()=>import('./IndicesGlobalMarkets/IndicesGlobalMarkets'));
const BenefitsofIndiciesTrading = lazy(()=>import('./BenefitsofIndiciesTrading/BenefitsofIndiciesTrading'));
const HowToTradeIndices = lazy(()=>import('./HowToTradeIndices/HowToTradeIndices'));

const IndicesRouting = () => {
  return (
    <div>
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar  />
        </Suspense>
      </div>
      <Suspense><IndicesHero/></Suspense>

      <Suspense><IndicesGlobalMarkets/></Suspense>

      <Suspense><BenefitsofIndiciesTrading/></Suspense>

      <Suspense><HowToTradeIndices/></Suspense>

      <Suspense><Footer/></Suspense> 
    </div>
  )
}

export default IndicesRouting

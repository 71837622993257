import React , {lazy,Suspense} from 'react'
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'))
const CommoditiesHero = lazy(()=>import('./CommoditiesHero/CommoditiesHero'))
const Footer = lazy(()=>import('../../Footer/Footer'))
const CommoditiesGlobalMarkets = lazy(()=>import('./CommoditiesGlobalMarkets/CommoditiesGlobalMarkets'))
const BenefitsofCommoditiesTrading = lazy(()=>import('./BenefitsofCommoditiesTrading/BenefitsofCommoditiesTrading'))
const HowToTradeCommodities = lazy(()=>import('./HowToTradeCommodities/HowToTradeCommodities'))

const CommoditiesRouting = () => {
  return (
    <div>
     <div className="navbarPositions">
        <Suspense fallback={<div>Looading...</div>}>
          <NavigationBar />
        </Suspense>
      </div>
      <Suspense>
      <CommoditiesHero/>
      </Suspense>
      <Suspense>
      <CommoditiesGlobalMarkets/>
      </Suspense>
      <Suspense>
      <BenefitsofCommoditiesTrading/>
      </Suspense>
      <Suspense>
      <HowToTradeCommodities/>
      </Suspense>
      <Suspense>
      <Footer/>
      </Suspense>
    </div>
  )
}

export default CommoditiesRouting

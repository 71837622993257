import React, {lazy,Suspense} from 'react'
import '../MarketSections.css'
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const ForexHero = lazy(()=>import('./ForexHero/ForexHero'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const ForexGlobalMarket = lazy(()=>import('./ForexGlobalMarkets/ForexGlobalMarket'));
const ForexTradingStrategies = lazy(()=>import('./ForexTradingStrategies/ForexTradingStrategies'));
const HowToTradeForex = lazy(()=>import('./HowToTradeForex/HowToTradeForex'));

const ForexRouting = () => {
  return (
    <div>
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar  />
        </Suspense>
      </div>


      <Suspense>
      <ForexHero/>
      </Suspense>


      <Suspense>
      <ForexGlobalMarket/>
      </Suspense>


      <Suspense>
      <ForexTradingStrategies/>
      </Suspense>


      <Suspense>
      <HowToTradeForex/>
      </Suspense>

      
      <Suspense>
      <Footer/>
      </Suspense>
    </div>
  )
}

export default ForexRouting

// ContactUsRouting.js
import React, {lazy, Suspense } from "react";
const NavigationBar = lazy(()=> import('../../Navbar/NavigationBar'));
const ContactHero = lazy(()=> import('./ContactHero/ContactHero'));
const ContactDetails = lazy(()=> import('./ContactDetails/ContactDetails'));
const Footer = lazy(()=> import('../Footer/Footer'));

const ContactUsRouting = () => {
  
 

  return (
    <div>
      <section  className="section">
        <Suspense fallback={<div></div>}>
        <div className='navbarPositions'>
            <NavigationBar  />
          </div>
      <ContactHero />
      {/* <ContactForm /> */}
      <ContactDetails />
      <Footer />
        </Suspense>
      </section>
      
    </div>
  );
};

export default ContactUsRouting;
import React , {lazy,Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer')); 
const AboutUsHero = lazy(()=>import('./AboutUsHero/AboutUsHero')); 
const CultureAtKama = lazy(()=>import('./CultureAtKama/CultureAtKama')); 
const VisionMission = lazy(()=>import('./VisionMission/VisionMission')); 
const WhyChooseUs = lazy(()=>import('./WhyChooseUs/WhyChooseUs')); 

const AboutUsRouting = () => {
  return (
    <div>
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar />
        </Suspense>
      </div>
      <Suspense><AboutUsHero/></Suspense>
      <Suspense><CultureAtKama/></Suspense>
      <Suspense><VisionMission/></Suspense>
      <Suspense><WhyChooseUs/></Suspense>
      <Suspense><Footer/></Suspense>
    </div>
  )
}

export default AboutUsRouting

import React , {lazy,Suspense} from 'react';
const NavigationBar = lazy(()=>import('../../../Navbar/NavigationBar'));
const Footer = lazy(()=>import('../../Footer/Footer'));
const StocksHero = lazy(()=>import('./StocksHero/StocksHero'));
const StocksGlobalMarkets = lazy(()=>import('./StocksGlobalMarkets/StocksGlobalMarkets'));
const BenefitsofStocksTrading = lazy(()=>import('./BenefitsofStocksTrading/BenefitsofStocksTrading'));
const HowToTradeStocks = lazy(()=>import('./HowToTradeStocks/HowToTradeStocks'));

const StocksRouting = () => {
  return (
    <div>
      <div className="navbarPositions">
        <Suspense fallback={<div></div>}>
          <NavigationBar  />
        </Suspense>
      </div>
      <Suspense><StocksHero/></Suspense>
      <Suspense><StocksGlobalMarkets/></Suspense>
      <Suspense><BenefitsofStocksTrading/></Suspense>
      <Suspense><HowToTradeStocks/></Suspense>
      <Suspense><Footer/></Suspense>
    </div>
  )
}

export default StocksRouting
